import {
  FormControlLabel,
  Typography,
  Checkbox,
  useTheme,
  Tooltip,
  Button,
  Drawer,
  Alert,
  Stack,
  Box,
} from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { TbArrowRightCircle, TbArrowLeftCircle } from "react-icons/tb";
import { addMonths, format, subMonths } from "date-fns";
import { IoPeopleCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import NotesIcon from "@mui/icons-material/Notes";
import CloseIcon from "@mui/icons-material/Close";
import { PiMagicWandFill } from "react-icons/pi";
import LoopIcon from "@mui/icons-material/Loop";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import enUS from "date-fns/locale/en-US";
import { useSnackbar } from "notistack";
import de from "date-fns/locale/de";

import {
  getAllTransactionsByParams,
  deleteBatchTransactions,
  addBatchTransactions,
  CurrencyPrefixIcon,
  getTransactionById,
  getStaffQueryKey,
  getTailwindColor,
  formatAmount,
} from "../../Helper/data";
import {
  setRefreshBalance,
  setRefreshData,
  setLoading,
} from "../../store/slices/appmain";
import {
  setTransactionsOverlayStatus,
  setPopupStatus4,
} from "../../store/slices/datasets";
import EnableInternalDSFunctions from "../Header/DatasetHeader/EnableInternalDSFunctions";
import useUpdateEffect from "../../hooks/4-useUpdateEffect/useUpdateEffect";
import RulesCommonView from "../../Pages/Settings/Rules/RulesCommonView";
import DescriptionInput from "../Overlay/DescriptionInput";
import { GlobalContext } from "../../GlobalContextWrapper";
import { formatDateToLocal } from "./../../Helper/data";
import TailwindButton from "../Overlay/TailwindButton";
import { Color, Constant, Fonts } from "../../Helper";
import RecurringFormView from "./RecurringFormView";
import EndPoints from "../../APICall/EndPoints";
import ActionButtonView from "./ActionBtnView";
import CategoryChip from "../CategoryChip";
import Android12Switch from "../A12Switch";
import DropDown from "../Overlay/DropDown";
import ScenarioView from "./ScenarioView";
import CostUnitView from "./CostUnitView";
import { queryClient } from "../../App";
import APICall from "../../APICall";
import StateView from "./StateView";
import TitleView from "./TitleView";
import IconView from "./IconView";
import store from "../../store";
import theme from "../../theme";
import Icon from "../Icon";

const DrawerModalEditForm = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);

  const seqFunctionRef = globalContext?.seqFunctionRef;
  const updatedObj = useRef({});
  const item = useRef(null);
  const rulesRef = useRef(null);
  const oldTransactionId = useRef(null);
  const oldRecurId = useRef(null);
  const internalDsRef = useRef(null);
  const isTransactionFormUpdated = useRef(false);
  const isBalanceAffected = useRef(false);
  const PausedDuration = useRef([]);
  const VariableRatesItemList = useRef(null);
  const PausedDurationItemList = useRef(null);
  const SpecialPaymentsItemList = useRef(null);

  //redux
  const transactionsOverlayStatus = useSelector(
    (state) => state.datasetSlice?.transactionsOverlayStatus
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const recurring_rulesById = useSelector(
    (state) => state.globalSlice?.recurring_rulesById
  );

  const transactionsItem = transactionsOverlayStatus?.payload?.item;
  const modalType = transactionsOverlayStatus?.payload?.modalType;
  const transaction_type = transactionsOverlayStatus?.payload?.transaction_type;

  const default_start_date = format(
    transactionsItem?.due_date
      ? new Date(transactionsItem?.due_date)
      : new Date(),
    "yyyy-MM-dd"
  );
  //state
  const [isRecurrenceUpdated, setIsRecurrenceUpdated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [isFormVisible, setFormVisible] = useState(modalType !== "edit");
  const [tab, setTab] = useState("single");

  const [cardItem, setCardItem] = useState({});

  const [recurring_Obj, setRecurring_Obj] = useState({
    name: "recurrence",
    recurring_type: "simple",
    salary_type: 2,
    value: transactionsItem?.gross_value
      ? Math.abs(transactionsItem?.gross_value)
      : 0,
    start_date: default_start_date,
    value_sets: [
      {
        base_value: 0,
        base_value_name: "ongoing_cost",
        title: "ongoing_cost",
        items: [],
      },
      {
        base_value: 0,
        base_value_name: "one_off_cost",
        title: "one_off_cost",
        items: [],
      },
    ],
  });

  const disabled = cardItem?.source === 2;
  const showSaved =
    modalType === "edit" &&
    (isRecurrenceUpdated || isTransactionFormUpdated.current);

  const isStaff = tab === "employee" || tab === "loan" || tab === "leasing";
  const isLoanSpecialPayments =
    cardItem?.state === "Booked" && cardItem?.recurring_rule;
  const isRecurringSeq =
    cardItem?.recurring_rule && Constant?.staffState?.includes(cardItem?.state);

  //api
  const getRecurringRule = async (id) => {
    await APICall("get", EndPoints.recurring_rules + `${id}/`).then(
      (response) => {
        if (response.status === 200 && response.data) {
          setTab(response.data?.recurring_type);

          let recurringItem = {
            ...response.data,
          };
          if (recurringItem?.recurring_type === "employee") {
            const StaffList = queryClient.getQueryData(getStaffQueryKey());
            const contact = StaffList?.find(
              (o) => o?.uuid === recurringItem?.contact
            );
            if (contact) {
              recurringItem.name = contact?.name;
            }
          }
          setRecurring_Obj(recurringItem);
        }
      }
    );
  };

  const updateCardByID = async (id, obj, doUpdate) => {
    await APICall("patch", EndPoints.transactions + `${id}/`, obj, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.data?.category?.[0]?.includes("Cannot set category.")) {
        rulesRef.current?.onOpen({
          payload: [{ ...obj, uuid: id }],
          response: [{ ...response?.data, uuid: id }],
          message: response?.data?.category?.[0],
          oldObj: obj,
          transaction_rule: response.data?.transaction_rule?.[0],
          rule_type: response.data?.type?.[0],
          title: obj?.title,
          transaction_type: transaction_type === "income" ? 1 : 2,
          category: obj?.category,
          callback: (updatedObj) => {
            isTransactionFormUpdated.current = true;
            setCardItem((draft) => ({ ...draft, ...updatedObj }));
            dispatch(setTransactionsOverlayStatus(null));
            if (showSaved || doUpdate) {
              dispatch(setRefreshData(Date.now()));
              if (transactionsOverlayStatus?.payload?.updateOverlay) {
                setTimeout(() => {
                  transactionsOverlayStatus?.payload?.updateOverlay({
                    from_edit_form: true,
                  });
                }, 0);
              }
            }
          },
        });
      } else {
        setCardItem((draft) => ({ ...draft, ...obj }));
        dispatch(setTransactionsOverlayStatus(null));
        if (showSaved || doUpdate) {
          dispatch(setRefreshData(Date.now()));
          if (transactionsOverlayStatus?.payload?.updateOverlay) {
            setTimeout(() => {
              transactionsOverlayStatus?.payload?.updateOverlay({
                from_edit_form: true,
              });
            }, 0);
          }
        }
      }
    });
  };

  const deleteCardByIdApi = async (id) => {
    await APICall("delete", EndPoints.transactions + `${id}/`).then(
      (response) => {
        if (response.status === 204 && response) {
          oldTransactionId.current = null;
          enqueueSnackbar(t("Card Deleted Successfully"));
          onClose(true);
        }
      }
    );
  };

  const addBatch = async (array) => {
    dispatch(setLoading(true));
    await addBatchTransactions(array);
    dispatch(setLoading(false));
    enqueueSnackbar(t("Cards Added Successfully"));
    onClose(true);
  };

  const deleteBatch = async (data) => {
    let uuids = data?.map((o1) => o1.uuid);
    dispatch(setLoading(true));
    const isDeleted = await deleteBatchTransactions(uuids);
    if (isDeleted) {
      dispatch(setLoading(false));
      onClose(true);
    }
  };

  const onChangeTransactionsType = async () => {
    let oldRecurData = await getAllTransactionsByParams({
      dataset: dataSetData?.uuid,
      recurring_rule: [oldRecurId.current],
      is_reconciled: false,
    });
    deleteBatch(oldRecurData);
  };

  //functions
  const onSaveRecurrence = async (obj) => {
    const { doClose = false, isPastIncluded = false } = obj;
    let deleteIds = [];
    if (oldTransactionId?.current) {
      deleteIds.push(oldTransactionId?.current);
    }

    seqFunctionRef?.current?.onSaveRecurrence({
      cardItem,
      setCardItem,
      recurring_Obj,
      setRecurring_Obj,
      transaction_type,
      deleteIds,
      updatedObj,
      doNotUpdateApp: true,
      onCloseEditForm: doClose ? () => onClose(true) : null,
      setShowError,
      isPastIncluded,
      isLoanSpecialPayments,
      PausedDuration: PausedDuration.current,
      PausedDurationItemList: PausedDurationItemList.current,
      VariableRatesItemList: VariableRatesItemList.current,
      SpecialPaymentsItemList: SpecialPaymentsItemList.current,
    });
  };

  const onClickEdit = () => {
    if (!global?.isInputFocused) {
      setFormVisible(!isFormVisible);
    }
  };

  const onClose = async (doUpdate) => {
    if (
      tab === "single" &&
      isTransactionFormUpdated.current &&
      cardItem?.uuid
    ) {
      await updateCardByID(
        cardItem?.uuid,
        updatedObj?.current?.cardItem,
        doUpdate
      );
    } else {
      dispatch(setTransactionsOverlayStatus(null));
      if (showSaved || doUpdate) {
        dispatch(setRefreshData(Date.now()));
        if (transactionsOverlayStatus?.payload?.updateOverlay) {
          setTimeout(() => {
            transactionsOverlayStatus?.payload?.updateOverlay({
              from_edit_form: true,
            });
          }, 0);
        }
      }
    }
  };

  const onCancel = async () => {
    dispatch(setTransactionsOverlayStatus(null));
  };

  const updateRecurrenceFlag = (obj) => {
    if (obj) {
      updatedObj.current = {
        ...updatedObj.current,
        cardItem: { ...(updatedObj.current?.cardItem || {}), ...obj },
      };
      setError({});
      setCardItem((prev) => ({
        ...prev,
        ...obj,
      }));
    }
    setShowError(false);
    setIsRecurrenceUpdated(true);
  };

  const updateTransactionFormFlag = (obj) => {
    if (obj) {
      updatedObj.current = {
        ...updatedObj.current,
        cardItem: { ...(updatedObj.current?.cardItem || {}), ...obj },
      };
      setCardItem((prev) => ({
        ...prev,
        ...obj,
      }));
    }
    setError({});
    isTransactionFormUpdated.current = true;
  };

  const updateFlag = (obj) => {
    if (modalType === "edit" ? isRecurringSeq : tab !== "single") {
      updateRecurrenceFlag(obj);
    } else {
      updateTransactionFormFlag(obj);
    }
  };

  const onBlurText = async () => {
    const obj = {
      title: cardItem.title,
      note: cardItem.note,
    };
    if (cardItem?.title?.trim() === "") {
      setError({ ...error, title: "title can not be empty" });
      return;
    }
    updateFlag(obj);
    global.isInputFocused = false;
  };

  const onClickState = (item) => {
    if (cardItem?.state !== item?.title) {
      let obj = {
        state: item.title,
        scenario: item?.title === "Booked" ? "Base" : cardItem?.scenario,
      };

      if (item?.title !== "Planned" && tab !== "single" && !isStaff) {
        setTab("single");
        if (recurring_Obj?.value) {
          obj.gross_value =
            transaction_type === "income"
              ? Math.abs(recurring_Obj?.value)
              : -Math.abs(recurring_Obj?.value);
          obj.tax = recurring_Obj?.tax;
        }
      }
      if (cardItem?.source === 2) {
        isBalanceAffected.current = true;
      }

      updateFlag(obj);
    }
  };

  const onClickScenario = (item) => {
    if (cardItem?.scenario !== item?.title) {
      const obj = { scenario: item.title };

      updateFlag(obj);
    }
  };

  const handleEditDescriptionChange = (e) => {
    let text = e.target.value;
    setCardItem({ ...cardItem, note: text });
  };

  const handleChangeHighLight = (e) => {
    const obj = {
      is_note_highlighted: e.target.checked,
    };
    updateFlag(obj);
  };

  const onChangeContact = (e, value) => {
    const obj = {
      contact: value?.uuid,
    };

    updateFlag(obj);
  };

  const onChangeCostUnit = (e, value) => {
    const obj = {
      cost_unit: value?.uuid,
    };

    updateFlag(obj);
  };

  const onChangeDataSource = (e, value) => {
    if (!value) return null;
    let source = 1;
    if (value?.type === 10) {
      source = 2;
    }
    if (value?.type === 2) {
      source = 3;
    }
    if (value?.type === 19) {
      source = 4;
    }

    const obj = {
      data_source: value?.uuid || dataSetData?.internal_data_source,
      source: source,
      transaction_system: value?.transaction_system,
    };
    updateFlag(obj);
  };

  const onCloseTransactionEdit = (obj, reason) => {
    if (modalType === "edit" && reason !== "escapeKeyDown") {
      if (!global?.isInputFocused) {
        if (tab === "single" && transaction_type === "expense") {
          if (!cardItem?.gross_value && cardItem?.gross_value?.trim() === "-") {
            enqueueSnackbar(t("please add an amount"), {
              variant: "warning",
              autoHideDuration: 5000,
              preventDuplicate: true,
            });
            return;
          }
        }
        if (tab === "single" && !cardItem?.due_date) {
          enqueueSnackbar(t("please add a due date"), {
            variant: "warning",
            autoHideDuration: 5000,
            preventDuplicate: true,
          });
          return;
        }
        if (isBalanceAffected) {
          dispatch(setRefreshBalance(Date.now()));
        }

        if (isRecurrenceUpdated) {
          onSaveRecurrence({
            doClose: true,
            isPastIncluded: obj?.isPastIncluded,
          });
        } else if (isTransactionFormUpdated.current && oldRecurId.current) {
          onChangeTransactionsType();
        } else {
          onClose();
        }
      }
    } else {
      onClose();
    }
  };

  const focusAmount = useCallback(() => {
    if (modalType !== "edit") {
      setTimeout(() => {
        const element = document.getElementById("currency-input");
        element?.focus();
      }, 1000);
    }
  }, [modalType]);

  //lifeCycle
  useEffect(() => {
    if (transactionsOverlayStatus?.payload?.item) {
      item.current = transactionsOverlayStatus?.payload?.item;
      setCardItem(transactionsOverlayStatus?.payload?.item);
      if (transactionsOverlayStatus?.payload?.recurringItem) {
        setRecurring_Obj(transactionsOverlayStatus?.payload?.recurringItem);
      }
      if (modalType === "edit") {
        updatedObj.current = null;
        isTransactionFormUpdated.current = false;
        isBalanceAffected.current = false;
        setIsRecurrenceUpdated(false);
      }
    }
  }, [modalType, transactionsOverlayStatus]);

  useEffect(() => {
    if (
      transactionsOverlayStatus?.open &&
      transactionsOverlayStatus?.overlay_type === "drawer_modal_edit_form"
    ) {
      setOpen(true);
      focusAmount();
    } else {
      setOpen(false);
    }
  }, [
    focusAmount,
    transactionsOverlayStatus?.open,
    transactionsOverlayStatus?.overlay_type,
  ]);

  useEffect(() => {
    if (
      cardItem?.recurring_rule &&
      cardItem?.uuid &&
      Constant?.staffState?.includes(cardItem?.state)
    ) {
      if (recurring_rulesById?.[cardItem?.recurring_rule]) {
        let data = recurring_rulesById?.[cardItem?.recurring_rule]?.[0];
        if (!data?.period) {
          setRecurring_Obj({
            ...data,
            recurring_type: "simple",
            value: cardItem?.gross_value,
          });
          setTab("simple");
        } else {
          let recurringItem = {
            ...data,
          };
          if (
            recurringItem?.contact &&
            recurringItem?.recurring_type === "employee"
          ) {
            const StaffList = queryClient.getQueryData(getStaffQueryKey());
            const contact = StaffList?.find(
              (o) => o?.uuid === recurringItem?.contact
            );
            if (contact) {
              recurringItem.name = contact?.name;
            }
          }
          setRecurring_Obj(recurringItem);
          setTab(data?.recurring_type);
          if (data?.recurring_type === "loan") {
            setFormVisible(true);
          }
        }
      } else {
        getRecurringRule(cardItem?.recurring_rule);
      }
    }
  }, [cardItem?.recurring_rule]);

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onCloseTransactionEdit}
      transitionDuration={300}
      SlideProps={{
        in: true,
      }}
      PaperProps={{
        sx: { borderRadius: 0 },
      }}
      sx={{
        zIndex: 1303,
        width: "100%",
        position: "relative",
      }}
    >
      <RulesCommonView ref={rulesRef} />
      <EnableInternalDSFunctions ref={internalDsRef} />
      <Box
        sx={{
          width: "45rem",
          padding: "2rem",
          pb: "6rem",
          position: "relative",
          overflowY: "auto",
          ...theme.thinScrollBar,
        }}
      >
        <HeaderView
          cardItem={cardItem}
          showError={showError}
          showSaved={showSaved}
          onCloseTransactionEdit={onCloseTransactionEdit}
        />

        {modalType === "edit" && !isFormVisible ? (
          <DatesView
            tab={tab}
            updatedObj={updatedObj}
            disabled={disabled}
            cardItem={cardItem}
            setCardItem={setCardItem}
            onClickEdit={onClickEdit}
            isFormVisible={isFormVisible}
            recurring_Obj={recurring_Obj}
            updateFlag={updateFlag}
            setRecurring_Obj={setRecurring_Obj}
            isLoanSpecialPayments={isLoanSpecialPayments}
          />
        ) : null}

        {isFormVisible ? (
          <RecurringFormView
            PausedDuration={PausedDuration}
            VariableRatesItemList={VariableRatesItemList}
            PausedDurationItemList={PausedDurationItemList}
            SpecialPaymentsItemList={SpecialPaymentsItemList}
            tab={tab}
            setTab={setTab}
            cardItem={cardItem}
            isRecurrenceUpdated={isRecurrenceUpdated}
            setIsRecurrenceUpdated={setIsRecurrenceUpdated}
            showError={showError}
            setShowError={setShowError}
            setError={setError}
            error={error}
            disabled={disabled}
            recurring_Obj={recurring_Obj}
            setRecurring_Obj={setRecurring_Obj}
            setCardItem={setCardItem}
            updateRecurrenceFlag={updateRecurrenceFlag}
            updateFlag={updateFlag}
            updatedObj={updatedObj}
            oldRecurId={oldRecurId}
            oldTransactionId={oldTransactionId}
            isLoanSpecialPayments={isLoanSpecialPayments}
          />
        ) : null}

        <TitleView
          cardItem={cardItem}
          setCardItem={setCardItem}
          setError={setError}
          modalType={modalType}
          error={error}
          disabled={disabled}
          tab={tab}
          showError={showError}
          updateFlag={updateFlag}
          label={tab === "employee" ? t("Planning Title") : t("Title")}
        />

        <CategoryChipView
          showError={showError}
          tab={tab}
          recurring_Obj={recurring_Obj}
          setRecurring_Obj={setRecurring_Obj}
          cardItem={cardItem}
          transaction_type={transaction_type}
          setCardItem={setCardItem}
          modalType={modalType}
          updatedObj={updatedObj}
          updateRecurrenceFlag={updateRecurrenceFlag}
          updateFlag={updateFlag}
        />
        <StateView
          tab={tab}
          cardItem={cardItem}
          onClick={onClickState}
          chipWidth={"11rem"}
          width="35rem"
          ml="2.55rem"
          titleLeft="0.2rem"
        />
        <ScenarioView
          cardItem={cardItem}
          onClick={onClickScenario}
          width="35rem"
          ml="2.55rem"
          titleLeft="0.2rem"
          chipWidth={"11rem"}
        />

        <ContactView
          transaction_type={transaction_type}
          cardItem={cardItem}
          onChangeContact={onChangeContact}
        />

        <NotesView
          cardItem={cardItem}
          onChange={handleEditDescriptionChange}
          onBlur={onBlurText}
        />

        <Tooltip
          title={t("edit_form_highlight_note_tooltip")}
          arrow
          placement="top"
        >
          <FormControlLabel
            label={t("highlight note")}
            control={
              <Checkbox
                checked={cardItem?.is_note_highlighted}
                onChange={handleChangeHighLight}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            sx={{
              ml: "2.55rem",
            }}
          />
        </Tooltip>

        <CostUnitView
          cardItem={cardItem}
          modalType={modalType}
          onChangeCostUnit={onChangeCostUnit}
          onChangeDataSource={onChangeDataSource}
        />
        <UpdateDatesView cardItem={cardItem} />
      </Box>

      <ActionButtonView
        internalDsRef={internalDsRef}
        cardItem={cardItem}
        tab={tab}
        disabled={disabled}
        showSaved={showSaved}
        isTransactionFormUpdated={isTransactionFormUpdated}
        recurring_Obj={recurring_Obj}
        onCloseTransactionEdit={onCloseTransactionEdit}
        onSaveRecurrence={onSaveRecurrence}
        onClose={onClose}
        onCancel={onCancel}
        addBatch={addBatch}
        updateFlag={updateFlag}
        deleteCardByIdApi={deleteCardByIdApi}
      />
    </Drawer>
  );
};

export default DrawerModalEditForm;

const HeaderView = ({
  cardItem,
  showSaved,
  showError,
  onCloseTransactionEdit,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Typography
      component={"div"}
      sx={{
        textAlign: "center",
        fontSize: "1.1rem",
        fontWeight: 500,
        textTransform: "uppercase",
        display: "flex",
        position: "relative",
        alignItems: "center",
        width: "100%",
        color: Color.tailwind.slate[500],
      }}
    >
      {formatDateToLocal(
        new Date(cardItem?.due_date ? cardItem?.due_date : new Date()),
        "MMMM yyyy"
      )}
      {showError ? (
        <Alert
          severity="warning"
          sx={{
            position: "fixed",
            top: "1.25rem",
            right: "46rem",
            boxShadow: "none !important",
            fontSize: "1rem",
            minHeight: "2rem",
            padding: "0.75rem 1.5rem",
            backgroundColor: "white",
            display: "inline-flex",
            alignItems: "start",
            maxWidth: "30rem",
            zIndex: 2,
            ml: "1.5rem",
            "& .MuiAlert-icon": {
              color: theme.palette.color.red[500],
              fontSize: "1.5rem",
              lineHeight: "1.5rem",
              alignItems: "center",
              p: 0,
            },
            "& .MuiAlert-message": {
              color: theme.palette.color.red[500],
              textAlign: "left",
              py: "0rem",
              display: "inline-flex",
            },
          }}
        >
          {t(typeof showError === "string" ? showError : showError?.errorText)}
        </Alert>
      ) : null}
      {showSaved && !showError ? (
        <Alert
          severity="success"
          sx={{
            boxShadow: "none !important",
            fontSize: "0.8rem",
            minHeight: "2rem",
            ml: "1.5rem",
            padding: "0rem 0.5rem",
            display: "inline-flex",
            alignItems: "center",
            alignSelf: "center",
            "& .MuiAlert-icon": {
              fontSize: "1rem",
              alignItems: "center",
            },
            "& .MuiAlert-message": {
              textAlign: "left",
              py: "0rem",
              display: "inline-flex",
            },
          }}
        >
          {t("Saved")}
        </Alert>
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "2rem",
          position: "absolute",
          right: 0,
        }}
      >
        <CloseIcon
          onClick={onCloseTransactionEdit}
          sx={{
            cursor: "pointer",
            color: Color.tailwind.slate[500],
          }}
        />
      </div>
    </Typography>
  );
};

const DatesView = ({
  tab,
  disabled,
  cardItem,
  setCardItem,
  isFormVisible,
  onClickEdit,
  updateFlag,
  recurring_Obj,
  setRecurring_Obj,
  updatedObj,
  isLoanSpecialPayments,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const scenarioByTitle = useSelector(
    (state) => state.globalSlice.scenarioByTitle
  );
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const _scenario = scenarioByTitle?.[cardItem?.scenario]?.[0];

  const start_date = cardItem?.recurring_rule
    ? recurring_Obj?.start_date
      ? format(new Date(recurring_Obj?.start_date), "MMM yy", {
          locale: profile?.locale === "de_DE" ? de : enUS,
        })
      : null
    : cardItem?.due_date
      ? format(new Date(cardItem?.due_date), "dd MMM yy", {
          locale: profile?.locale === "de_DE" ? de : enUS,
        })
      : null;
  const end_date =
    cardItem?.recurring_rule && recurring_Obj?.end_date
      ? format(new Date(recurring_Obj?.end_date), "MMM yy")
      : null;

  const onClickLeftArrow = ({ isExtend = false, isShrink = false }) => {
    if (tab === "single") {
      const old_date = cardItem?.due_date || cardItem?.invoice_date;
      const new_date = format(subMonths(new Date(old_date), 1), "yyyy-MM-dd");
      const obj = {
        due_date: new_date,
        invoice_date: null,
      };
      setCardItem((draft) => ({ ...draft, ...obj }));
      updateFlag(obj);
    } else {
      const new_start_date = format(
        subMonths(new Date(recurring_Obj?.start_date), isShrink ? -1 : 1),
        "yyyy-MM-dd"
      );
      const new_end_date = format(
        subMonths(
          new Date(recurring_Obj?.end_date),
          isExtend || isShrink ? 0 : 1
        ),
        "yyyy-MM-dd"
      );
      const obj = {
        start_date: new_start_date,
        end_date: new_end_date,
      };
      updatedObj.current = {
        ...updatedObj.current,
        ...obj,
      };
      setRecurring_Obj({
        ...recurring_Obj,
        ...obj,
      });

      updateFlag();
    }
  };

  const onClickRightArrow = ({ isExtend = false, isShrink = false }) => {
    if (tab === "single") {
      const old_date = cardItem?.due_date || cardItem?.invoice_date;
      const new_date = format(addMonths(new Date(old_date), 1), "yyyy-MM-dd");
      const obj = {
        due_date: new_date,
        invoice_date: null,
      };
      setCardItem((draft) => ({ ...draft, ...obj }));
      updateFlag(obj);
    } else {
      const new_start_date = format(
        addMonths(
          new Date(recurring_Obj?.start_date),
          isExtend || isShrink ? 0 : 1
        ),
        "yyyy-MM-dd"
      );
      const new_end_date = format(
        addMonths(new Date(recurring_Obj?.end_date), isShrink ? -1 : 1),
        "yyyy-MM-dd"
      );
      const obj = {
        start_date: new_start_date,
        end_date: new_end_date,
      };
      updatedObj.current = {
        ...updatedObj.current,
        ...obj,
      };
      setRecurring_Obj({
        ...recurring_Obj,
        ...obj,
      });
      updateFlag();
    }
  };

  const onClickRightExtendArrow = () => {
    onClickRightArrow({ isExtend: true });
  };

  const onClickLeftExtendArrow = () => {
    onClickLeftArrow({ isExtend: true });
  };

  const onClickLeftShrinkArrow = () => {
    onClickLeftArrow({ isShrink: true });
  };

  const onClickRightShrinkArrow = () => {
    onClickRightArrow({ isShrink: true });
  };

  const ArrowButton = ({ tooltip, icon, disabled, onClick }) => {
    return (
      <Tooltip followCursor placement="top" title={t(tooltip)}>
        <span>
          <Button
            disabled={disabled}
            onClick={onClick}
            sx={{
              py: "0.5rem",
              px: ".25rem",
              minWidth: "unset",
              color: theme.palette.primary.main,
              borderRadius: 2,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: theme.palette.primary[100],
              },
              "& svg": {
                fontSize: "1.2rem",
              },
            }}
          >
            {icon}
          </Button>
        </span>
      </Tooltip>
    );
  };

  return (
    <div style={{ display: "flex", marginTop: "2rem", width: "100%" }}>
      {!cardItem?.recurring_rule ? (
        <Icon
          icon={CurrencyPrefixIcon?.[dataSetData?.currency]}
          size="1rem"
          style={{ height: "fit-content", mr: "1.5rem" }}
          color={Color.theme.blueGrey[700]}
        ></Icon>
      ) : (
        <LoopIcon
          sx={{
            color: Color.theme.blueGrey[700],
            ml: "0.2rem",
            mr: "1.5rem",
            fontSize: "1.4rem",
          }}
        />
      )}
      <div
        style={{
          width: "100%",
          padding: "1rem",
          backgroundColor: getTailwindColor(_scenario?.color, 50),
          borderRadius: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "0.2rem",
          }}
        >
          <AmountReadView cardItem={cardItem} />
          {isLoanSpecialPayments ? null : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.25rem",
              }}
            >
              {!disabled ? (
                <ArrowButton
                  icon={<KeyboardDoubleArrowLeftIcon />}
                  tooltip="edit_form_date_left_click_tooltip"
                  onClick={onClickLeftArrow}
                />
              ) : null}
              {tab !== "single" && !disabled ? (
                <ArrowButton
                  icon={
                    <KeyboardArrowLeftIcon style={{ fontSize: "1.4rem" }} />
                  }
                  tooltip="edit_form_date_extend_left_click_tooltip"
                  onClick={onClickLeftExtendArrow}
                />
              ) : null}
              {tab !== "single" && !disabled ? (
                <ArrowButton
                  icon={
                    <KeyboardArrowRightIcon style={{ fontSize: "1.4rem" }} />
                  }
                  tooltip="edit_form_date_shrink_left_click_tooltip"
                  onClick={onClickLeftShrinkArrow}
                />
              ) : null}
              <Typography
                sx={{
                  py: "0.5rem",
                  px: "1.25rem",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "0.85rem",
                  fontWeight: 600,
                  backgroundColor: Color.white,
                  borderRadius: 2,
                  textTransform: "initial",
                  color: theme.palette.primary.main,
                }}
              >
                {`${start_date}${end_date ? ` -> ${end_date}` : ""}`}
              </Typography>
              {tab !== "single" && !disabled ? (
                <ArrowButton
                  icon={
                    <KeyboardArrowLeftIcon style={{ fontSize: "1.4rem" }} />
                  }
                  tooltip="edit_form_date_shrink_right_click_tooltip"
                  onClick={onClickRightShrinkArrow}
                />
              ) : null}
              {tab !== "single" && !disabled ? (
                <ArrowButton
                  icon={
                    <KeyboardArrowRightIcon style={{ fontSize: "1.4rem" }} />
                  }
                  tooltip="edit_form_date_extend_right_click_tooltip"
                  onClick={onClickRightExtendArrow}
                />
              ) : null}
              {!disabled ? (
                <ArrowButton
                  icon={<KeyboardDoubleArrowRightIcon />}
                  tooltip="edit_form_date_right_click_tooltip"
                  onClick={onClickRightArrow}
                />
              ) : null}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "baseline",
              marginRight: "1rem",
            }}
          >
            {cardItem.state === "Planned" ||
            dataSetData?.internal_data_source ===
              cardItem?.data_source ? null : (
              <IconView item={cardItem} />
            )}
            {cardItem?.recurring_rule &&
            Constant?.staffState?.includes(cardItem?.state) ? (
              <Tooltip
                title={`${recurring_Obj?.recurring_type} recurring`}
                placement="top"
                followCursor
              >
                <Box
                  sx={{
                    display: "flex",
                    color: theme.palette.primary.main,

                    "& svg": {
                      fontSize: "1.25rem",
                      mr: "0.5rem",
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {recurring_Obj?.icon}
                </Box>
              </Tooltip>
            ) : null}
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "1rem",
                fontWeight: 600,
                fontFamily: Fonts?.Text,
                color: theme.palette.primary.main,

                display: "flex",
                alignItems: "center",
              }}
            >
              {cardItem?.recurring_rule
                ? !Constant?.staffState?.includes(cardItem?.state)
                  ? cardItem?.bank_transaction_type
                  : recurring_Obj?.recurring_type === "employee"
                    ? t("Staff")
                    : recurring_Obj?.recurring_type === "loan"
                      ? t("fixed_loan")
                      : recurring_Obj?.recurring_type === "leasing"
                        ? t("leasing")
                        : `Recurring - ${
                            recurring_Obj?.repetition === 1
                              ? "Monthly"
                              : `Every ${recurring_Obj?.repetition} Months`
                          }`
                : ""}
            </Typography>
          </div>
          {!isFormVisible ? (
            <Typography
              onClick={onClickEdit}
              sx={{
                textAlign: "center",
                fontSize: "0.85rem",
                fontWeight: 700,

                display: "flex",
                alignItems: "center",
                color: theme.palette.primary.main,

                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {t("Edit")}
            </Typography>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const AmountReadView = ({
  cardItem,
  fontSize = "1.5rem",
  showIcon = false,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Typography
      sx={{
        textAlign: "center",
        fontSize: fontSize,
        fontWeight: 700,

        display: "flex",
        alignItems: "center",
        color:
          cardItem?.gross_value && cardItem?.gross_value >= 0
            ? Color.tailwind.green[500]
            : Color.tailwind.red[500],
        "&:hover": {
          "& #amount_edit_icon": {
            display: "flex",
          },
        },
      }}
    >
      {formatAmount({
        amount: String(
          parseFloat(
            isNaN(cardItem?.gross_value) || !cardItem?.gross_value
              ? 0
              : cardItem?.gross_value ?? 0
          ).toFixed(2)
        ),
      })}

      {showIcon ? (
        <EditIcon
          onClick={onClick}
          id="amount_edit_icon"
          sx={{
            display: "none",
            cursor: "pointer",
            marginLeft: "1rem",
            fontSize: "2.5rem",
            color: theme.palette.primary.main,
          }}
        />
      ) : null}
    </Typography>
  );
};

const CategoryChipView = ({
  showError,
  modalType,
  tab,
  recurring_Obj,
  cardItem,
  setCardItem,
  updateFlag,
  updateRecurrenceFlag,
  updatedObj,
  transaction_type,
  setRecurring_Obj,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  //state
  const [loanIncomeTransaction, setLoanIncomeTransaction] = useState({
    state: "Booked",
    scenario: "Base",
    bank_transaction_type: "LOAN_PAYMENT",
  });

  //api
  const updateRecurringRules = async (id, obj) => {
    await APICall("patch", EndPoints.recurring_rules + `${id}/`, obj).then(
      async (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  const getLoanTransaction = async (id) => {
    const dataSetData = store.getState()?.boardSlice?.dataSetData;

    try {
      const data = await getAllTransactionsByParams({
        dataset: dataSetData?.uuid,
        category_dataset: dataSetData?.use_global_categories
          ? null
          : dataSetData?.uuid,
        is_reconciled: false,
        order_by: "-due_date",
        recurring_rule: [id],
        bank_transaction_type: "LOAN_PAYMENT",
      });
      if (data?.[0]) {
        updatedObj.current = {
          ...updatedObj.current,
          loanIncomeTransaction: data?.[0],
        };
        setLoanIncomeTransaction(data?.[0]);
      } else {
        updatedObj.current = {
          ...updatedObj.current,
          loanIncomeTransaction,
        };
      }
    } catch (error) {}
  };

  //lifecycle
  useEffect(() => {
    if (
      recurring_Obj?.recurring_type === "loan" ||
      recurring_Obj?.recurring_type === "leasing"
    ) {
      if (recurring_Obj?.uuid && modalType === "edit") {
        getLoanTransaction(recurring_Obj?.uuid);
      } else {
        updatedObj.current = {
          ...updatedObj.current,
          loanIncomeTransaction,
        };
      }
    } else {
      updatedObj.current = {
        ...updatedObj.current,
        loanIncomeTransaction: null,
      };
    }
  }, [modalType, recurring_Obj?.recurring_type, recurring_Obj?.uuid]);

  //functions
  const onClickCategoryTitle = (e, item) => {
    if (item?.children?.length === 0) {
      let obj = {
        ...cardItem,
        category: item?.uuid,
        title: cardItem?.title,
      };
      updatedObj.current = {
        ...updatedObj.current,
        ...obj,
      };
      updateFlag(obj);
      setCardItem((prev) => ({
        ...prev,
        ...obj,
      }));
      setTimeout(() => {
        dispatch(setPopupStatus4(null));
      }, 0);
    }
  };

  const onDeleteCategory = () => {
    const obj = { category: null };
    updateFlag(obj);
  };

  const onClickCategory = (e) => {
    dispatch(
      setPopupStatus4({
        open: true,
        anchorEl: e.currentTarget,
        overlay_type: "category",
        payload: {
          ruleTitle: cardItem?.title,
          type: transaction_type === "income" ? 1 : 2,
          onClickCategoryTitle,
        },
      })
    );
  };

  ////////
  const onClickDepartmentTitle = (e, item) => {
    if (item?.children?.length === 0) {
      updateRecurrenceFlag();

      const obj = {
        category: item?.uuid,
      };

      setRecurring_Obj((prev) => ({
        ...prev,
        ...obj,
      }));
      dispatch(setPopupStatus4(null));
      if (!recurring_Obj?.is_department_separate) {
        setCardItem((prev) => ({
          ...prev,
          ...obj,
        }));
      }
    }
  };

  const onDeleteDepartment = () => {
    updateRecurrenceFlag();
    const obj = {
      category: null,
    };
    setRecurring_Obj((prev) => ({
      ...prev,
      ...obj,
    }));
    if (!recurring_Obj?.is_department_separate) {
      setCardItem((prev) => ({
        ...prev,
        ...obj,
      }));
    }
  };

  const onClickDepartment = (e) => {
    dispatch(
      setPopupStatus4({
        open: true,
        anchorEl: e.currentTarget,
        overlay_type: "category",
        payload: {
          ruleTitle: recurring_Obj?.name,
          type: 2,
          onClickCategoryTitle: onClickDepartmentTitle,
        },
      })
    );
  };

  ////////
  const onClickLoanIncomeCategoryTitle = (e, item) => {
    if (item?.children?.length === 0) {
      updateRecurrenceFlag();

      let obj = {
        category: item?.uuid,
        tax: null,
        tax_value: 0,
      };
      updatedObj.current = {
        ...updatedObj.current,
        loanIncomeTransaction: {
          ...updatedObj?.current?.loanIncomeTransaction,
          ...obj,
        },
      };
      dispatch(setPopupStatus4(null));
      setLoanIncomeTransaction((prev) => ({ ...prev, ...obj }));
    }
  };

  const onDeleteLoanIncomeCategory = () => {
    updateRecurrenceFlag();
    const obj = {
      category: null,
      tax: null,
      tax_value: 0,
    };
    updatedObj.current = {
      ...updatedObj.current,
      loanIncomeTransaction: {
        ...updatedObj?.current?.loanIncomeTransaction,
        ...obj,
      },
    };
    setLoanIncomeTransaction((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const onClickLoanIncomeCategory = (e) => {
    dispatch(
      setPopupStatus4({
        open: true,
        anchorEl: e.currentTarget,
        overlay_type: "category",
        payload: {
          // ruleTitle: recurring_Obj?.name,
          type: 1,
          onClickCategoryTitle: onClickLoanIncomeCategoryTitle,
        },
      })
    );
  };

  ////////
  const onChange = (e) => {
    updateRecurrenceFlag();
    setRecurring_Obj({
      ...recurring_Obj,
      is_department_separate: e.target.checked,
    });

    if (!e.target.checked) {
      setCardItem((prev) => ({
        ...prev,
        category: recurring_Obj?.category,
      }));
    }

    if (modalType === "edit") {
      updateRecurringRules(recurring_Obj?.uuid, {
        is_department_separate: e.target.checked,
      });
    }
  };

  return (
    <div style={{ display: "flex", marginTop: "1.5rem" }}>
      <LocalOfferOutlinedIcon
        sx={{
          color: theme.palette.color.blueGrey[700],
          ml: "0.2rem",
          mr: "1.6rem",
          fontSize: "1.4rem",
        }}
      />
      <Stack>
        {tab === "employee" ? (
          <div
            style={{
              width: "100%",
              display: "inline-flex",
              alignItems: "start",
              flexDirection: "column",
              justifyContent: "start",
              marginBottom: "0.5rem",
            }}
          >
            <Typography
              variant="h6"
              component={"h6"}
              fontSize="0.9rem"
              fontWeight={"fontWeightMediumBold"}
              color={
                showError && !Boolean(recurring_Obj?.category)
                  ? "error.main"
                  : "color.mainTitle"
              }
              sx={{
                display: "flex",
                alignItems: "center",
                mr: "2rem",
              }}
            >
              {t("label_department")}
            </Typography>
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{ mt: "1.5rem" }}
            >
              <CategoryChip
                width="11rem"
                height="2.2rem"
                fontSize="0.9rem"
                onClick={onClickDepartment}
                onDelete={onDeleteDepartment}
                showDelete={recurring_Obj?.category}
                categoryId={recurring_Obj?.category ?? null}
                noCategoryLabel={"Set Department"}
              />
              <Tooltip
                title={t(`department_separate_switch_tooltip`)}
                placement="top"
                arrow
              >
                <Android12Switch
                  checked={recurring_Obj?.is_department_separate}
                  onChange={onChange}
                  sx={{
                    ml: 8,
                    mr: 2,
                  }}
                />
              </Tooltip>
              <Typography variant="caption" fontWeight={"fontWeightMedium"}>
                {recurring_Obj?.is_department_separate
                  ? t("department_separated_category")
                  : t("department_not_separated_category")}
              </Typography>
            </Stack>
          </div>
        ) : null}
        {tab === "loan" || tab === "leasing" ? (
          <div
            style={{
              width: "100%",
              display: "inline-flex",
              alignItems: "start",
              flexDirection: "column",
              justifyContent: "start",
              marginBottom: "1rem",
            }}
          >
            <Typography
              variant="h6"
              component={"h6"}
              fontSize="0.9rem"
              fontWeight={"fontWeightMediumBold"}
              color={
                showError && !Boolean(loanIncomeTransaction?.category)
                  ? "error.main"
                  : "color.mainTitle"
              }
              sx={{
                display: "flex",
                alignItems: "center",
                mr: "2rem",
              }}
            >
              {t("loan_form_income_category")}
            </Typography>

            <CategoryChip
              width="11rem"
              height="2.2rem"
              fontSize="0.9rem"
              onClick={onClickLoanIncomeCategory}
              onDelete={onDeleteLoanIncomeCategory}
              showDelete={loanIncomeTransaction?.category}
              categoryId={loanIncomeTransaction?.category ?? null}
              noCategoryLabel={"Set Income Category"}
              style={{
                mt: "1.5rem",
              }}
            />
          </div>
        ) : null}
        {(tab === "employee" ? recurring_Obj?.is_department_separate : true) ? (
          <div
            style={{
              display: "inline-flex",
              alignItems: "start",
              flexDirection: "column",
              justifyContent: "start",
              marginBottom: "0.5rem",
              marginTop: tab === "employee" ? "2rem" : 0,
            }}
          >
            <Typography
              variant="h6"
              component={"h6"}
              fontSize="0.9rem"
              fontWeight={"fontWeightMediumBold"}
              color="color.mainTitle"
            >
              {t("category")}
            </Typography>
            <Stack direction={"row"} alignItems={"center"}>
              <CategoryChip
                width="11rem"
                height="2.2rem"
                fontSize="0.9rem"
                onClick={onClickCategory}
                onDelete={onDeleteCategory}
                showDelete={cardItem?.category}
                categoryId={cardItem?.category ?? null}
                style={{
                  marginTop: "1.5rem",
                }}
              />
              {modalType === "edit" ? (
                <CreateRuleButton
                  theme={theme}
                  cardItem={cardItem}
                  setCardItem={setCardItem}
                  updateFlag={updateFlag}
                />
              ) : null}
            </Stack>
          </div>
        ) : null}
      </Stack>
    </div>
  );
};

const CreateRuleButton = ({ theme, cardItem, setCardItem, updateFlag }) => {
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);
  const categoryRef = globalContext?.categoryRef;
  const refreshData = useSelector((state) => state.appSlice?.refreshData);
  const getTransaction = async () => {
    const data = await getTransactionById(cardItem?.uuid);
    if (data) {
      if (cardItem.category !== data.category) {
        updateFlag({ category: data?.category });
      }
      setCardItem(data);
    }
  };

  useUpdateEffect(() => {
    if (cardItem?.uuid) {
      getTransaction();
    }
  }, [refreshData]);

  const onClick = () => {
    let transactionsRow = {
      transaction_type: cardItem?.gross_value >= 0 ? 1 : 2,
      category: cardItem?.category,
      title: cardItem?.title,
      note: cardItem?.note,
    };

    categoryRef?.current?.createNewRules({
      transactionsRow,
    });
  };
  return (
    <TailwindButton
      variant="outlined"
      onClick={onClick}
      icon={<PiMagicWandFill style={{ fontSize: "1.1rem" }} />}
      tooltip={t("transaction_form_add_manual_rule_tooltip")}
      text={t("transaction_form_add_manual_rule")}
      color={theme.palette.color.white}
      sx={{
        mt: "1.5rem",
        backgroundImage: `linear-gradient(to bottom right, #6101D1, #9D4AFF)`,
        color: theme.palette.color.white,
        p: "0.55rem",
        gap: "0.5rem",
        border: `1px solid ${theme.palette?.primary.main}`,
        "&:hover": {
          backgroundImage: `linear-gradient(to bottom right, #6101D1, #9D4AFF)`,
          border: `1px solid ${theme.palette?.primary.main}`,
        },
      }}
    />
  );
};

const ContactView = ({ transaction_type, onChangeContact, cardItem }) => {
  const { t } = useTranslation();

  //redux
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );
  const transactionSystemById = useSelector(
    (state) => state.globalSlice?.transactionSystemById
  );
  const contacts = useSelector((state) => state.globalSlice?.contacts);
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const contactsById = useSelector((state) => state.globalSlice.contactsById);
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const isFinbanAccount = profile?.email?.includes("@finban.io");

  const contactsList = useMemo(() => {
    const type = transaction_type === "income" ? 1 : 2;
    const transaction_system = datasetAccountList
      ?.filter((o1) => o1.transaction_system)
      ?.map((o1) => transactionSystemById?.[o1?.transaction_system]?.[0]?.name);
    const data = contacts?.filter(
      (o1) =>
        (o1?.dataset === dataset ||
          transaction_system?.includes(o1?.transaction_system)) &&
        o1.type === type
    );

    return data;
  }, [
    contacts,
    dataset,
    datasetAccountList,
    transactionSystemById,
    transaction_type,
  ]);

  return (
    <div style={{ display: "flex", marginTop: "2rem", width: "100%" }}>
      <IoPeopleCircleOutline
        style={{
          color: theme.palette.color.blueGrey[600],
          marginLeft: "0.2rem",
          marginRight: "1rem",
          fontSize: "1.5rem",
        }}
      />
      <DropDown
        disablePortal
        disabled={!isFinbanAccount}
        hideTitle
        fontSize="0.9rem"
        name="contact"
        options={contactsList}
        value={contactsById?.[cardItem?.contact]?.[0]}
        onChange={onChangeContact}
        getOptionLabel={(option) => option?.name ?? ""}
        renderOption={(option) => option?.name ?? ""}
        label={t("Contact")}
        tooltip={t("Contact")}
        likeGoogle
        variant="filled"
      />
    </div>
  );
};

const NotesView = ({ cardItem, onChange, onBlur }) => {
  const { t } = useTranslation();
  const onFocus = () => {
    global.isInputFocused = true;
  };
  return (
    <div style={{ display: "flex", marginTop: "2rem", width: "100%" }}>
      <NotesIcon
        sx={{
          color: Color.theme.blueGrey[700],
          mt: !cardItem?.note ? "0.75rem" : 0,
          ml: "0.2rem",
          mr: "0.8rem",
          fontSize: "1.4rem",
        }}
      />
      <DescriptionInput
        value={cardItem?.note || ""}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        likeGoogle
        variant="filled"
        hideTitle
        fontSize={"0.9rem"}
        tooltip={t("Note_Optional")}
        label={t("Note_Optional")}
        sx={{ width: "100%" }}
      />
    </div>
  );
};

const UpdateDatesView = ({ cardItem }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (!cardItem?.last_modified_date && !cardItem?.creation_date) return null;
  return (
    <Stack
      direction={"row"}
      alignItems={"start"}
      justifyContent={"end"}
      sx={{
        width: "100%",
        mb: "4rem",
        backgroundColor: theme.palette.color.white,
        zIndex: 6,
      }}
    >
      <Typography
        variant="overline"
        fontWeight={"fontWeightMedium"}
        color="color.slate.700"
      >
        {t("transaction_updated_date")}:{" "}
        <b>
          {cardItem?.last_modified_date
            ? formatDateToLocal(cardItem?.last_modified_date)
            : ""}
        </b>
      </Typography>
      <Typography
        variant="overline"
        fontWeight={"fontWeightMedium"}
        color="color.slate.700"
        sx={{ ml: "2rem" }}
      >
        {t("transaction_created_date")}:{" "}
        <b>
          {cardItem?.creation_date
            ? formatDateToLocal(cardItem?.creation_date)
            : ""}
        </b>
      </Typography>
    </Stack>
  );
};
